import { BibliotecaComponent } from './biblioteca/biblioteca.component';
import { NgModule } from '@angular/core';
import { Routes, RouterModule, ExtraOptions } from '@angular/router';

const routerOptions: ExtraOptions = {
  scrollPositionRestoration: 'enabled',
  anchorScrolling: 'enabled',
  scrollOffset: [0, 64],
};

const routes: Routes = [
  {
    path: 'juego',
    loadChildren: () => import("./trivia/trivia.module").then(mod => mod.TriviaModule)
  },

  {
    path: 'biblioteca_virtual',
    component: BibliotecaComponent
  },
  /** 
  {
    path: 'blog',
    component: BlogComponent
  },*/
  {
    path: '',
    loadChildren: () => import("./home/home.module").then(mod => mod.HomeModule)
  },
  {
    path: 'admin',
    loadChildren: () => import("./admin/admin.module").then(mod => mod.AdminModule)
  },
  {
    path: '**',
    redirectTo: ''
    //loadChildren: () => import("./home/home.module").then(mod => mod.HomeModule)
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes,
    {
      anchorScrolling: "enabled",
      scrollPositionRestoration: 'enabled'
    })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
