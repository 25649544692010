import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { auth } from 'firebase/app';
import { AngularFireAuth } from '@angular/fire/auth';
import { AngularFirestore, AngularFirestoreDocument } from '@angular/fire/firestore';
import { User, Student } from '../services/types';
import { Observable, of } from 'rxjs';
import { switchMap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  user$: Observable<User>;

  constructor(
    private afAuth: AngularFireAuth,
    private afs: AngularFirestore,
    private router: Router,
  ) {
    this.user$ = this.afAuth.authState.pipe(
      switchMap(user => {
        if (user) {
          // cheking if user is logged, then return his/her info         
          return this.afs.doc<User>(`users/${user.uid}`).valueChanges();
        } else {
          // if not logged, return an observable with null
          return of(null);
        }
      })
    );
  }

  /**
   * @desc Sign Up with google's services
   * @returns user credentials <Promise<any>>
   */
  async signUp(data: any): Promise<any> {
    const credential = await this.afAuth.auth.createUserWithEmailAndPassword(data.email, data.password);
    return this.updateUserData(credential.user, data);
  }

  /**
   * @desc Sign In with google's services
   */
  singIn(email: string, pass: string): Promise<any> {
    return this.afAuth.auth.signInWithEmailAndPassword(email, pass);
  }

  private updateUserData(user: any, data: any) {
    const userRef: AngularFirestoreDocument<User> = this.afs.doc(`users/${user.uid}`);
    const studentRef: AngularFirestoreDocument<Student> = this.afs.doc(`schools/${data.sid}/studentsList/${user.uid}`);

    const _data = {
      uid: user.uid,
      email: user.email,
      displayName: data.displayName,
      sid: data.school.id,
      school: data.school.name,
      level: data.level['level'],
      grade: data.grade,
      points: 0,
      garbage: 0,
      status: 'Activo',
      regDate: new Date()
    }

    const _student = {
      id: user.uid,
      displayName: data.displayName,
      email: data.email,
      status: 'Activo',
      regDate: new Date()
    }

    const batch = this.afs.firestore.batch();

    batch.set(userRef.ref, _data, { merge: true });
    batch.set(studentRef.ref, _student);

    return batch.commit();
  }

  async signOut() {
    await this.afAuth.auth.signOut();
    this.router.navigate([''])
  }

}
