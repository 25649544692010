import { map, mergeMap, shareReplay } from 'rxjs/operators';
import { FormGroup, FormBuilder } from '@angular/forms';
import { Observable, combineLatest, of } from 'rxjs';
import { DatabaseService } from './../core/services/database.service';
import { Component, OnInit, Renderer2 } from '@angular/core';

@Component({
  selector: 'app-biblioteca',
  templateUrl: './biblioteca.component.html',
  styleUrls: ['./biblioteca.component.css']
})
export class BibliotecaComponent implements OnInit {

  searchFormGroup: FormGroup

  find: boolean = false
  arrayFound: Array<any> = []

  tema: string = 'a escoger'
  choose: string
  choose$: Array<any>
  images: Array<string> = [
    "../../assets/imagenes/png/001-save.png",
    "../../assets/imagenes/png/003-climate-change.png",
    "../../assets/imagenes/png/002-recycling.png",
    "../../assets/imagenes/png/004-power-plant.png",
  ]
  fileTypes: Array<string> = [
    'Video',
    'Imagen',
    'Documento',
    'PDF'
  ]

  searchTittle$: Observable<any[]>

  fileObservableObject: Array<Observable<any>> = []
  constructor(public dbs: DatabaseService, private renderer: Renderer2, private fb: FormBuilder) { }

  ngOnInit() {
    this.createForm()
    this.dbs.currentDataLibraryTree
      .subscribe(res => {
        if (!res)
          return;

        res.forEach((element, index) => {
          this.fileObservableObject[index] = this.dbs.libraryTreeCollection.doc(element.id).collection('files').valueChanges();
        })
        this.searchTittle$ =
          combineLatest(
            this.fileObservableObject
          ).pipe(
            mergeMap(el => of(el)),
            map(el => {
              let array = []
              el.forEach(a => {
                a.forEach(b => {
                  array.push(b)
                })
              })
              return array
            }),
            shareReplay(1)
          )
      })


  }
  createForm(): void {
    this.searchFormGroup = this.fb.group({
      input: null
    });
  }

  change(category) {
    this.find = false
    if (this.tema == category.name) {
      this.tema = 'nada';
      this.choose$ = null;
    } else {
      this.tema = category.name;
      this.choose = category.id;
      this.dbs.libraryTreeCollection.doc(category.id).collection('files').valueChanges().subscribe(res => {
        this.choose$ = res;

      })
    }
  }

  search() {
    let input = this.searchFormGroup.get('input').value
    this.searchTittle$.subscribe(res => {
      this.find = true
      if (res && input) {
        this.arrayFound = res.filter(el => {
          let title = el.name.toLowerCase().includes(input.toLowerCase())
          let type = el.type.toLowerCase().includes(input.toLowerCase())
          return title || type
        })
      }

    })
  }
}
