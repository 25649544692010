// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  firebaseConfig: {
    apiKey: "AIzaSyD59XF4QRw84Lcnf8-A-4P150nEOFjyfOI",
    authDomain: "mujeres-ecosolidarias.firebaseapp.com",
    databaseURL: "https://mujeres-ecosolidarias.firebaseio.com",
    projectId: "mujeres-ecosolidarias",
    storageBucket: "mujeres-ecosolidarias.appspot.com",
    messagingSenderId: "354037423813",
    appId: "1:354037423813:web:73201fe7000b0a83addaac",
    measurementId: "G-B1TW6Y6WFJ"
  }
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
