import { Injectable } from '@angular/core';
import { AngularFirestore, AngularFirestoreCollection, AngularFirestoreDocument } from '@angular/fire/firestore';
import { AngularFireStorage } from '@angular/fire/storage';
import { School, User, LandingContent, AboutUsContent, CountersContent, ServicesContent, AlliesContent, ContactContent, LibraryTree, Service, Level, Student, Question, Topic, Blog, BlogContent } from './types';
import { BehaviorSubject, of, Observable, forkJoin, combineLatest } from 'rxjs';
import { finalize, take, shareReplay, filter, distinctUntilChanged, tap, map } from 'rxjs/operators';
import { AuthService } from './auth.service';

@Injectable({
  providedIn: 'root'
})
export class DatabaseService {

  /** VAR DECLARATION */

  // USERS
  public usersCollection: AngularFirestoreCollection<User>;
  public users$: Observable<User[]>

  public users: Array<User> = [];

  public dataUsers = new BehaviorSubject<User[]>([]);
  public currentDataUsers = this.dataUsers.asObservable();

  // SCHOOLS
  public schoolsCollection: AngularFirestoreCollection<School>;
  public schools$: Observable<School[]>;

  // STUDENTS
  public studentsListCollection: AngularFirestoreCollection<Student>;
  public students$: Observable<Student[]>;

  /** WEBSITE CONTET */
  // LANDING
  public landingContentDocument: AngularFirestoreDocument<LandingContent>;
  public landingContent: LandingContent = null;

  public dataLandingContent = new BehaviorSubject<LandingContent>(null);
  public currentDataLandingContent = this.dataLandingContent.asObservable();

  // ABOUT US
  public aboutUsContentDocument: AngularFirestoreDocument<AboutUsContent>;
  public aboutUsContent: AboutUsContent = null;

  public dataAboutUsContent = new BehaviorSubject<AboutUsContent>(null);
  public currentDataAboutUsContent = this.dataAboutUsContent.asObservable();

  // COUNTERS
  public countersContentDocument: AngularFirestoreDocument<CountersContent>;
  public countersContent: CountersContent = null;

  public dataCountersContent = new BehaviorSubject<CountersContent>(null);
  public currentDataCountersContent = this.dataCountersContent.asObservable();

  // COUNTERS
  public servicesContentDocument: AngularFirestoreDocument<ServicesContent>;
  public servicesContent: ServicesContent = null;

  public dataServicesContent = new BehaviorSubject<ServicesContent>(null);
  public currentDataServicesContent = this.dataServicesContent.asObservable();

  // ALLIES
  public alliesContentDocument: AngularFirestoreDocument<AlliesContent>;
  public alliesContent: AlliesContent = null;

  public dataAlliesContent = new BehaviorSubject<AlliesContent>(null);
  public currentDataAlliesContent = this.dataAlliesContent.asObservable();

  // CONTACT
  public contactContentDocument: AngularFirestoreDocument<ContactContent>;
  public contactContent: ContactContent = null;

  public dataContactContent = new BehaviorSubject<ContactContent>(null);
  public currentDataContactContent = this.dataContactContent.asObservable();

  /** LIBRARY CONTENT */
  // LIBRARY TREE
  public libraryTreeCollection: AngularFirestoreCollection<LibraryTree>;
  public libraryTree: Array<LibraryTree> = [];

  public dataLibraryTree = new BehaviorSubject<LibraryTree[]>([]);
  public currentDataLibraryTree = this.dataLibraryTree.asObservable();

  /** SERVICES */
  // SERVICE 1
  public service1Document: AngularFirestoreDocument<Service>;
  public service1: Service = null;

  public dataService1 = new BehaviorSubject<Service>(null);
  public currentDataService1 = this.dataService1.asObservable();

  // SERVICE 2
  public service2Document: AngularFirestoreDocument<Service>;
  public service2: Service = null;

  public dataService2 = new BehaviorSubject<Service>(null);
  public currentDataService2 = this.dataService2.asObservable();

  // SERVICE 3
  public service3Document: AngularFirestoreDocument<Service>;
  public service3: Service = null;

  public dataService3 = new BehaviorSubject<Service>(null);
  public currentDataService3 = this.dataService3.asObservable();

  // SERVICE 4
  public service4Document: AngularFirestoreDocument<Service>;
  public service4: Service = null;

  public dataService4 = new BehaviorSubject<Service>(null);
  public currentDataService4 = this.dataService4.asObservable();

  // SERVICE 5
  public service5Document: AngularFirestoreDocument<Service>;
  public service5: Service = null;

  public dataService5 = new BehaviorSubject<Service>(null);
  public currentDataService5 = this.dataService5.asObservable();


  //TRIVIA

  public topicCollection: AngularFirestoreCollection<any>;
  public topicContent = null;
  public questionsCollection: AngularFirestoreCollection<any>;

  // *********** BLOG
  public blogsCollection: AngularFirestoreCollection<Blog>;
  blogs$: Observable<Blog[]>;

  // *********** Galery
  public galeryCollection: AngularFirestoreCollection<Blog>;
  galery$: Observable<Blog[]>;

  constructor(
    public auth: AuthService,
    public af: AngularFirestore,
    private storage: AngularFireStorage,
  ) {
    this.getLandingContent();
    this.getAboutUsContent();
    this.getCountersContent();
    this.getServicesContent();
    this.getAlliesContent();
    this.getContactContent();
    this.getLibraryTree();
    this.getServices();
    this.getSchools();
    this.getBlogs();
    this.getGalery();
    this.getUsers();
  }

  /**
   * @description Esta funcion retorna la coleccion de colegios registrados en el sistema
   */
  getSchools(): void {
    this.schoolsCollection = this.af.collection<School>(`schools`, ref => ref.orderBy('regDate', 'desc'));
    this.schools$ =
      this.schoolsCollection.valueChanges()
        .pipe(
          shareReplay(1)
        );
  }

  getUsers(): void {
    this.usersCollection = this.af.collection<User>(`users`, ref => ref.orderBy('regDate', 'desc'));
    this.users$ =
      this.usersCollection.valueChanges()
        .pipe(
          shareReplay(1)
        );
  }

  saveSchool(form: any): void {
    const data: School = {
      id: '',
      name: form['name'],
      totalStudents: form['totalStudents'],
      code: form['code'],
      points: 0,
      garbage: 0,
      status: 'Activo',
      regDate: new Date()
    };

    this.schoolsCollection
      .add(data)
      .then(ref => {
        ref.update({ id: ref.id });
      })
      .catch(err => {
        console.log(err);
      })
  }

  toggleStatusSchool(sid: string, status: string): void {
    this.schoolsCollection
      .doc(sid)
      .update({ status: status })
      .catch(err => {
        console.log(err);
      })
  }

  deleteSchool(sid: string): void {
    this.schoolsCollection
      .doc(sid)
      .delete()
      .catch(err => {
        console.log(err);
      })
  }

  editSchool(form: any, sid: string): void {

    this.schoolsCollection
      .doc(sid)
      .update(form)
      .catch(err => {
        console.log(err);
      })
  }

  /**
   * @description Esta funcion retorna la coleccion de DNIs correspondientes al colegio
   * @param sid <string> id del colegio
   */
  getStudentsList(sid: string) {
    this.studentsListCollection = this.af.collection<Student>(`schools/${sid}/studentsList`, ref => ref.orderBy('displayName', 'asc'));
    this.students$ =
      this.studentsListCollection.valueChanges()
        .pipe(
          shareReplay(1)
        );
  }

  /**
   * @description Esta funcion retorna el contenido del landing page
   */
  getLandingContent(): void {
    this.landingContentDocument = this.af.doc<LandingContent>(`content/landing`);
    this.landingContentDocument.valueChanges()
      .subscribe(res => {
        if (!res)
          return;
        this.landingContent = res;
        this.dataLandingContent.next(res);
      })
  }

  /**
   * @description Esta funcion retorna el contenido del Nosotros
   */
  getAboutUsContent(): void {
    this.aboutUsContentDocument = this.af.doc<AboutUsContent>(`content/aboutUs`);
    this.aboutUsContentDocument.valueChanges()
      .subscribe(res => {
        if (!res)
          return;
        this.aboutUsContent = res;
        this.dataAboutUsContent.next(res);
      })
  }

  /**
   * @description Esta funcion retorna el contenido de los Contadores e Indicadores
   */
  getCountersContent(): void {
    this.countersContentDocument = this.af.doc<CountersContent>(`content/counters`);
    this.countersContentDocument.valueChanges()
      .subscribe(res => {
        if (!res)
          return;
        this.countersContent = res;
        this.dataCountersContent.next(res);
      })
  }

  /**
   * @description Esta funcion retorna el contenido de los servicios
   */
  getServicesContent(): void {
    this.servicesContentDocument = this.af.doc<ServicesContent>(`content/services`);
    this.servicesContentDocument.valueChanges()
      .subscribe(res => {
        if (!res)
          return;
        this.servicesContent = res;
        this.dataServicesContent.next(res);
      })
  }

  /**
   * @description Esta funcion retorna el contenido de los aliados
   */
  getAlliesContent(): void {
    this.alliesContentDocument = this.af.doc<AlliesContent>(`content/allies`);
    this.alliesContentDocument.valueChanges()
      .subscribe(res => {
        if (!res)
          return;
        this.alliesContent = res;
        this.dataAlliesContent.next(res);
      })
  }

  /**
   * @description Esta funcion retorna el contenido de contáctanos
   */
  getContactContent(): void {
    this.contactContentDocument = this.af.doc<ContactContent>(`content/contact`);
    this.contactContentDocument.valueChanges()
      .subscribe(res => {
        if (!res)
          return;
        this.contactContent = res;
        this.dataContactContent.next(res);
      })
  }

  saveLandingContent(form: any, image: any): Promise<any> {
    const data = {
      title: form['title'],
      subtitle: form['subtitle'],
      description: form['description'],
      editedAt: new Date()
    }

    if (image) {

      const filePath = `/content/landing/${Date.now()}_portada`;
      const fileRef = this.storage.ref(filePath);
      const task = this.storage.upload(filePath, image);

      task.snapshotChanges().pipe(
        finalize(() => {
          fileRef.getDownloadURL().subscribe(res => {
            if (res) {
              this.landingContentDocument.update({ image: res })
            }
          })
        })
      )
        .subscribe()
    }

    return this.landingContentDocument.update(data);
  }

  saveAboutUsContent(paragraphs: any, image: any): Promise<any> {
    const data = {
      description: paragraphs,
      editedAt: new Date()
    }

    if (image) {

      const filePath = `/content/about/${Date.now()}_portada`;
      const fileRef = this.storage.ref(filePath);
      const task = this.storage.upload(filePath, image);

      task.snapshotChanges().pipe(
        finalize(() => {
          fileRef.getDownloadURL().subscribe(res => {
            if (res) {
              this.aboutUsContentDocument.update({ image: res })
            }
          })
        })
      )
        .subscribe()
    }

    return this.aboutUsContentDocument.update(data);
  }

  saveCountersContent(form: any, image: any): Promise<any> {
    const data = {
      counterName1: form['counterName1'],
      counterNumber1: form['counterNumber1'],
      counterName2: form['counterName2'],
      counterNumber2: form['counterNumber2'],
      counterName3: form['counterName3'],
      counterNumber3: form['counterNumber3'],
      counterName4: form['counterName4'],
      counterNumber4: form['counterNumber4'],
      editedAt: new Date()
    }

    if (image) {

      const filePath = `/content/counters/${Date.now()}_${image.name}`;
      const fileRef = this.storage.ref(filePath);
      const task = this.storage.upload(filePath, image);

      task.snapshotChanges().pipe(
        finalize(() => {
          fileRef.getDownloadURL().subscribe(res => {
            if (res) {
              this.countersContentDocument.update({ image: res })
            }
          })
        })
      )
        .subscribe()
    }

    return this.countersContentDocument.update(data);
  }

  saveServicesContent(form: any, image1: any, image2: any, image3: any, image4: any, image5: any): Promise<any> {
    const data = {
      serviceName1: form['serviceName1'],
      serviceDescription1: form['serviceDescription1'],
      serviceName2: form['serviceName2'],
      serviceDescription2: form['serviceDescription2'],
      serviceName3: form['serviceName3'],
      serviceDescription3: form['serviceDescription3'],
      serviceName4: form['serviceName4'],
      serviceDescription4: form['serviceDescription4'],
      serviceName5: form['serviceName5'],
      serviceDescription5: form['serviceDescription5'],
      editedAt: new Date()
    }

    if (image1) {

      const filePath = `/content/services/${Date.now()}_servicio1`;
      const fileRef = this.storage.ref(filePath);
      const task = this.storage.upload(filePath, image1);

      task.snapshotChanges().pipe(
        finalize(() => {
          fileRef.getDownloadURL().subscribe(res => {
            if (res) {
              this.servicesContentDocument.update({ image1: res })
            }
          })
        })
      )
        .subscribe()
    }

    if (image2) {

      const filePath = `/content/services/${Date.now()}_servicio2`;
      const fileRef = this.storage.ref(filePath);
      const task = this.storage.upload(filePath, image2);

      task.snapshotChanges().pipe(
        finalize(() => {
          fileRef.getDownloadURL().subscribe(res => {
            if (res) {
              this.servicesContentDocument.update({ image2: res })
            }
          })
        })
      )
        .subscribe()
    }

    if (image3) {

      const filePath = `/content/services/${Date.now()}_servicio3`;
      const fileRef = this.storage.ref(filePath);
      const task = this.storage.upload(filePath, image3);

      task.snapshotChanges().pipe(
        finalize(() => {
          fileRef.getDownloadURL().subscribe(res => {
            if (res) {
              this.servicesContentDocument.update({ image3: res })
            }
          })
        })
      )
        .subscribe()
    }

    if (image4) {

      const filePath = `/content/services/${Date.now()}_servicio4`;
      const fileRef = this.storage.ref(filePath);
      const task = this.storage.upload(filePath, image4);

      task.snapshotChanges().pipe(
        finalize(() => {
          fileRef.getDownloadURL().subscribe(res => {
            if (res) {
              this.servicesContentDocument.update({ image4: res })
            }
          })
        })
      )
        .subscribe()
    }

    if (image5) {

      const filePath = `/content/services/${Date.now()}_servicio5`;
      const fileRef = this.storage.ref(filePath);
      const task = this.storage.upload(filePath, image5);

      task.snapshotChanges().pipe(
        finalize(() => {
          fileRef.getDownloadURL().subscribe(res => {
            if (res) {
              this.servicesContentDocument.update({ image5: res })
            }
          })
        })
      )
        .subscribe()
    }

    return this.servicesContentDocument.update(data);
  }

  saveAlliesContent(selectImages: Array<any>, imageAllies: Array<any>): Promise<any> {
    if (selectImages.length) {
      selectImages.map(image => {
        const filePath = `/content/allies/${Date.now()}_${image.name}`;
        const fileRef = this.storage.ref(filePath);
        const task = this.storage.upload(filePath, image);

        task.snapshotChanges()
          .pipe(
            finalize(() => {
              fileRef.getDownloadURL().subscribe(res => {
                if (res) {
                  imageAllies.push(res)
                  this.alliesContentDocument.update({ images: imageAllies })
                }
              })
            })
          )
          .subscribe()
      })

    } else {
      this.alliesContentDocument.update({ images: imageAllies })
    }

    return Promise.resolve();
  }

  saveContactContent(form: any): Promise<any> {
    const data = {
      location1: form['location1'],
      location2: form['location2'],
      phone1: form['phone1'],
      phone2: form['phone2'],
      email1: form['email1'],
      email2: form['email2'],
      editedAt: new Date()
    }

    return this.contactContentDocument.update(data);
  }

  /** LIBRARY METHODS */

  getLibraryTree(): void {
    this.libraryTreeCollection = this.af.collection(`library`, ref => ref.orderBy('regDate', 'asc'));
    this.libraryTreeCollection.valueChanges()
      .subscribe(res => {
        if (!res)
          return;
        this.libraryTree = res;
        this.dataLibraryTree.next(res);
      })
  }

  saveCategory(form: any): Promise<any> {
    let data = {
      id: '',
      name: form['category'],
      regDate: new Date(),
      editedAt: new Date()
    };

    return this.libraryTreeCollection
      .add(data)
      .then(ref => {
        ref.update({ id: ref.id })
      })
  }

  deleteCategory(id: string): Promise<any> {
    const delete$ =
      this.libraryTreeCollection
        .doc(id)
        .delete();

    return delete$;

  }

  saveVideoLibrary(form: any) {
    let data = {
      id: '',
      name: form['name'],
      type: form['type']['name'],
      url: form['video'],
      regDate: new Date(),
      editedAt: new Date()
    }
    let doc = this.af.firestore.collection(`library/${form['category']}/files`).doc()
    data.id = doc.id
    doc.set(data)
    return this.libraryTreeCollection.valueChanges()
  }

  saveFileLibraryTree(form: any, file: any): Observable<any> {
    if (file) {
      const filePath = `/library/${file.name}`;
      const fileRef = this.storage.ref(filePath);
      const task = this.storage.upload(filePath, file);

      return task.snapshotChanges().pipe(
        finalize(() => {
          fileRef.getDownloadURL().subscribe(res => {
            if (res) {
              this.libraryTreeCollection
                .doc(form['category'])
                .collection('files')
                .add({
                  id: '',
                  name: form['name'],
                  type: form['type']['name'],
                  url: res,
                  regDate: new Date(),
                  editedAt: new Date()
                })
                .then(ref => {
                  ref.update({ id: ref.id })
                })
            }
          })
        })
      );

    }
  }

  deleteFile(cat_id: string, file_id: string): Promise<any> {
    const delete$ =
      this.libraryTreeCollection
        .doc(cat_id)
        .collection('files')
        .doc(file_id)
        .delete();

    return delete$;
  }

  getServices(): void {
    this.service1Document = this.af.doc(`services/service1`);
    this.service1Document.valueChanges()
      .subscribe(res => {
        if (!res)
          return
        this.service1 = res;
        this.dataService1.next(res);
      });

    this.service2Document = this.af.doc(`services/service2`);
    this.service2Document.valueChanges()
      .subscribe(res => {
        if (!res)
          return
        this.service2 = res;
        this.dataService2.next(res);
      });

    this.service3Document = this.af.doc(`services/service3`);
    this.service3Document.valueChanges()
      .subscribe(res => {
        if (!res)
          return
        this.service3 = res;
        this.dataService3.next(res);
      });

    this.service4Document = this.af.doc(`services/service4`);
    this.service4Document.valueChanges()
      .subscribe(res => {
        if (!res)
          return
        this.service4 = res;
        this.dataService4.next(res);
      });

    this.service5Document = this.af.doc(`services/service5`);
    this.service5Document.valueChanges()
      .subscribe(res => {
        if (!res)
          return
        this.service5 = res;
        this.dataService5.next(res);
      });
  }

  saveService1(form: any, image1: any, image2: any): Observable<any> {

    let data = {
      description: form['description']
    }

    const update$ = this.service1Document.update(data);

    let image1$: Observable<any> = of(null);
    let image2$: Observable<any> = of(null);

    if (image1) {
      const filePath = `/services/${Date.now()}_${image1.name}`;
      const fileRef = this.storage.ref(filePath);
      const task = this.storage.upload(filePath, image1);

      image1$ = task.snapshotChanges().pipe(
        finalize(() => {
          fileRef.getDownloadURL().subscribe(res => {
            if (res) {
              this.service1Document
                .update({
                  image1: res
                })
            }
          })
        })
      );
    }

    if (image2) {
      const filePath = `/services/${Date.now()}_${image2.name}`;
      const fileRef = this.storage.ref(filePath);
      const task = this.storage.upload(filePath, image2);

      image2$ = task.snapshotChanges().pipe(
        finalize(() => {
          fileRef.getDownloadURL().subscribe(res => {
            if (res) {
              this.service1Document
                .update({
                  image2: res
                })
            }
          })
        })
      );
    }

    // if (image1 && !image2) {
    //   const fork$ =
    //     forkJoin(
    //       image1$,
    //       update$
    //     )
    //   return fork$;
    // }

    // if (!image1 && image2) {
    //   const fork$ =
    //     forkJoin(
    //       image2$,
    //       update$
    //     )
    //   return fork$;
    // }

    // if (image1 && image2) {
    const fork$ =
      forkJoin(
        image1$,
        image2$,
        update$
      )
    return fork$;
    // }
  }

  saveService2(form: any, image1: any, image2: any): Observable<any> {

    let data = {
      description: form['description']
    }

    const update$ = this.service2Document.update(data);

    let image1$: Observable<any> = of(null);
    let image2$: Observable<any> = of(null);

    if (image1) {
      const filePath = `/services/${Date.now()}_${image1.name}`;
      const fileRef = this.storage.ref(filePath);
      const task = this.storage.upload(filePath, image1);

      image1$ = task.snapshotChanges().pipe(
        finalize(() => {
          fileRef.getDownloadURL().subscribe(res => {
            if (res) {
              this.service2Document
                .update({
                  image1: res
                })
            }
          })
        })
      );
    }

    if (image2) {
      const filePath = `/services/${Date.now()}_${image2.name}`;
      const fileRef = this.storage.ref(filePath);
      const task = this.storage.upload(filePath, image2);

      image2$ = task.snapshotChanges().pipe(
        finalize(() => {
          fileRef.getDownloadURL().subscribe(res => {
            if (res) {
              this.service2Document
                .update({
                  image2: res
                })
            }
          })
        })
      );
    }

    // if (image1 && !image2) {
    //   const fork$ =
    //     forkJoin(
    //       image1$,
    //       update$
    //     )
    //   return fork$;
    // }

    // if (!image1 && image2) {
    //   const fork$ =
    //     forkJoin(
    //       image2$,
    //       update$
    //     )
    //   return fork$;
    // }

    // if (image1 && image2) {
    const fork$ =
      forkJoin(
        image1$,
        image2$,
        update$
      )
    return fork$;
    // }
  }

  saveService3(form: any, image1: any, image2: any): Observable<any> {

    const update$ = this.service3Document.update(form);

    let image1$: Observable<any> = of(null);
    let image2$: Observable<any> = of(null);

    if (image1) {
      const filePath = `/services/${Date.now()}_${image1.name}`;
      const fileRef = this.storage.ref(filePath);
      const task = this.storage.upload(filePath, image1);

      image1$ = task.snapshotChanges().pipe(
        finalize(() => {
          fileRef.getDownloadURL().subscribe(res => {
            if (res) {
              this.service3Document
                .update({
                  image1: res
                })
            }
          })
        })
      );
    }

    if (image2) {
      const filePath = `/services/${Date.now()}_${image2.name}`;
      const fileRef = this.storage.ref(filePath);
      const task = this.storage.upload(filePath, image2);

      image2$ = task.snapshotChanges().pipe(
        finalize(() => {
          fileRef.getDownloadURL().subscribe(res => {
            if (res) {
              this.service3Document
                .update({
                  image2: res
                })
            }
          })
        })
      );
    }

    // if (image1 && !image2) {
    //   const fork$ =
    //     forkJoin(
    //       image1$,
    //       update$
    //     )
    //   return fork$;
    // }

    // if (!image1 && image2) {
    //   const fork$ =
    //     forkJoin(
    //       image2$,
    //       update$
    //     )
    //   return fork$;
    // }

    // if (image1 && image2) {
    const fork$ =
      forkJoin(
        image1$,
        image2$,
        update$
      )
    return fork$;
    // }
  }

  saveService4(form: any, image1: any, image2: any, image3: any, image4: any): Observable<any> {

    const update$ = this.service4Document.update(form);

    let image1$: Observable<any> = of(null);
    let image2$: Observable<any> = of(null);
    let image3$: Observable<any> = of(null);
    let image4$: Observable<any> = of(null);

    if (image1) {
      const filePath = `/services/${Date.now()}_${image1.name}`;
      const fileRef = this.storage.ref(filePath);
      const task = this.storage.upload(filePath, image1);

      image1$ = task.snapshotChanges().pipe(
        finalize(() => {
          fileRef.getDownloadURL().subscribe(res => {
            if (res) {
              this.service4Document
                .update({
                  image1: res
                })
            }
          })
        })
      );
    }

    if (image2) {
      const filePath = `/services/${Date.now()}_${image2.name}`;
      const fileRef = this.storage.ref(filePath);
      const task = this.storage.upload(filePath, image2);

      image2$ = task.snapshotChanges().pipe(
        finalize(() => {
          fileRef.getDownloadURL().subscribe(res => {
            if (res) {
              this.service4Document
                .update({
                  image2: res
                })
            }
          })
        })
      );
    }

    if (image3) {
      const filePath = `/services/${Date.now()}_${image3.name}`;
      const fileRef = this.storage.ref(filePath);
      const task = this.storage.upload(filePath, image3);

      image3$ = task.snapshotChanges().pipe(
        finalize(() => {
          fileRef.getDownloadURL().subscribe(res => {
            if (res) {
              this.service4Document
                .update({
                  image3: res
                })
            }
          })
        })
      );
    }

    if (image4) {
      const filePath = `/services/${Date.now()}_${image4.name}`;
      const fileRef = this.storage.ref(filePath);
      const task = this.storage.upload(filePath, image4);

      image4$ = task.snapshotChanges().pipe(
        finalize(() => {
          fileRef.getDownloadURL().subscribe(res => {
            if (res) {
              this.service4Document
                .update({
                  image4: res
                })
            }
          })
        })
      );
    }

    // if (image1 && !image2) {
    //   const fork$ =
    //     forkJoin(
    //       image1$,
    //       update$
    //     )
    //   return fork$;
    // }

    // if (!image1 && image2) {
    //   const fork$ =
    //     forkJoin(
    //       image2$,
    //       update$
    //     )
    //   return fork$;
    // }

    // if (image1 && image2) {
    const fork$ =
      forkJoin(
        image1$,
        image2$,
        image3$,
        image4$,
        update$
      )
    return fork$;
    // }
  }

  saveService5(form: any, image1: any, image2: any, image3: any): Observable<any> {

    const update$ = this.service5Document.update(form);

    let image1$: Observable<any> = of(null);
    let image2$: Observable<any> = of(null);
    let image3$: Observable<any> = of(null);

    if (image1) {
      const filePath = `/services/${Date.now()}_${image1.name}`;
      const fileRef = this.storage.ref(filePath);
      const task = this.storage.upload(filePath, image1);

      image1$ = task.snapshotChanges().pipe(
        finalize(() => {
          fileRef.getDownloadURL().subscribe(res => {
            if (res) {
              this.service5Document
                .update({
                  image1: res
                })
            }
          })
        })
      );
    }

    if (image2) {
      const filePath = `/services/${Date.now()}_${image2.name}`;
      const fileRef = this.storage.ref(filePath);
      const task = this.storage.upload(filePath, image2);

      image2$ = task.snapshotChanges().pipe(
        finalize(() => {
          fileRef.getDownloadURL().subscribe(res => {
            if (res) {
              this.service5Document
                .update({
                  image2: res
                })
            }
          })
        })
      );
    }

    if (image3) {
      const filePath = `/services/${Date.now()}_${image3.name}`;
      const fileRef = this.storage.ref(filePath);
      const task = this.storage.upload(filePath, image3);

      image3$ = task.snapshotChanges().pipe(
        finalize(() => {
          fileRef.getDownloadURL().subscribe(res => {
            if (res) {
              this.service5Document
                .update({
                  image3: res
                })
            }
          })
        })
      );
    }

    const fork$ =
      forkJoin(
        image1$,
        image2$,
        image3$,
        update$
      )
    return fork$;
  }

  //TRIVIA

  gettopic(level: string): Observable<Topic[]> {

    this.topicCollection = this.af.collection(`trivia/${level}/play`, ref => ref.orderBy('topic', 'asc'));
    return this.topicCollection.valueChanges()
  }
  getquestions(level: string, topic: Topic): Observable<any> {
    this.questionsCollection = this.af.collection(`trivia/${level}/play/${topic.id}/questions`, ref => ref.orderBy('question', 'asc'));
    return this.questionsCollection.valueChanges().pipe(shareReplay(1))
  }

  editTopic(level: string, topic: Topic) {
    let doc = this.af.collection(`trivia/${level}/play`).doc(topic.id)
    doc.update(topic)
  }

  createquestioncollection(level: string, topic: Topic, question: Question) {
    const doc = this.af.firestore.collection(`trivia/${level}/play/${topic.id}/questions`).doc();
    question.id = doc.id;
    return doc.set(question);
  }

  editQuestionCollection(level: string, topic: Topic, question: Question) {
    const doc = this.af.firestore.collection(`trivia/${level}/play/${topic.id}/questions`).doc(question.id);
    return doc.update(question);
  }

  deleteQuestionCollection(level: string, topic: Topic, question: Question) {
    const doc = this.af.firestore.collection(`trivia/${level}/play/${topic.id}/questions`).doc(question.id);
    return doc.delete();
  }

  saveSchoolPoints() {
    let points$=
      combineLatest(
        this.schools$,
        this.users$
      ).pipe(
        map(([schools, users]) => {
          schools.forEach(school => {
            let pointsSchool = 0
            users.forEach(user => {
              if (user.school == school.name) {
                pointsSchool += user.points
              }
            })
            this.editSchool({ points: pointsSchool }, school.id)
          })
        })
      ).subscribe()

  }
  savepoints(user) {
    const doc = this.af.collection(`users`).doc(user.uid);
    return doc.update(user);
  }
  //Galery

  getGalery(): void {
    this.galeryCollection = this.af.collection('galery', ref => ref.orderBy('image', 'asc'));
    this.galery$ = this.galeryCollection.valueChanges().pipe(shareReplay(1));
  }

  saveImage(imageFile) {
    const doc = this.af.firestore.collection(`galery`).doc()
    if (imageFile) {
      let name = imageFile.name.replace('(', '_')
      const filePath = `/galery/images/${Date.now()}_${name}`;
      const fileRef = this.storage.ref(filePath);
      const task = this.storage.upload(filePath, imageFile);

      task.snapshotChanges().pipe(
        finalize(() => {
          fileRef.getDownloadURL().subscribe(res => {
            if (res) {
              doc.set(
                {
                  image: res,
                  id: doc.id
                }
              )
            }
          })
        })
      )
        .subscribe();
    }

  }

  deleteImage(image) {
    const doc = this.af.firestore.collection(`galery`).doc(image)
    doc.delete()
  }
  // BLOG
  getBlogs(): void {
    this.blogsCollection = this.af.collection('blog', ref => ref.orderBy('createdAt', 'desc'));
    this.blogs$ = this.blogsCollection.valueChanges().pipe(shareReplay(1));
  }

  saveBlogEntry(contentList: Array<BlogContent>, data: Blog, background: any): void {
    //let batch = this.af.firestore.batch();
    let doc = this.af.firestore.collection(`blog`).doc();
    data.id = doc.id
    doc.set(data)

    if (background) {
      let name = data.title.replace('(', '-')
      const filePath = `/blog/images/${Date.now()}_${name}`;
      const fileRef = this.storage.ref(filePath);
      const task = this.storage.upload(filePath, background);

      task.snapshotChanges().pipe(
        finalize(() => {
          fileRef.getDownloadURL().subscribe(res => {
            if (res) {
              data.background = res
              doc.update({ background: res })
            }
          })
        })
      )
        .subscribe();
    }

    let images = []

    contentList.forEach((content, ind) => {
      if (content.type === 'image' && content.imageFile) {
        images.push({
          image: content.imageFile,
          index: ind
        })
      }
    })
    if (images.length) {
      images.forEach((el, ind) => {
        let title = el.image.name.replace("(", "_")
        const filePath = `/blog/images/${Date.now()}_${title}`;
        const fileRef = this.storage.ref(filePath);
        const task = this.storage.upload(filePath, el.image);

        task.snapshotChanges().pipe(
          finalize(() => {
            fileRef.getDownloadURL().subscribe(res => {
              if (res) {
                contentList[el.index].image = res;
                contentList[el.index].imageFile = null;
                if (ind == images.length - 1) {
                  console.log('guarda');
                  doc.update({ content: contentList })
                }
              }
            })
          })
        )
          .subscribe();
      })

    } else {
      doc.update({ content: contentList })
    }



  }

  editPost(data: Blog, contentList: Array<BlogContent>, background: any) {
    let doc = this.af.firestore.collection(`blog`).doc(data.id);
    doc.update(data)

    if (background) {
      let name = data.title.replace('(', '-')
      const filePath = `/blog/images/${Date.now()}_${name}`;
      const fileRef = this.storage.ref(filePath);
      const task = this.storage.upload(filePath, background);

      task.snapshotChanges().pipe(
        finalize(() => {
          fileRef.getDownloadURL().subscribe(res => {
            if (res) {
              data.background = res
              doc.update({ background: res })
            }
          })
        })
      )
        .subscribe();
    }

    let images = []

    contentList.forEach((content, ind) => {
      if (content.type === 'image' && content.imageFile) {
        images.push({
          image: content.imageFile,
          index: ind
        })
      }
    })

    if (images.length) {
      images.forEach((el, ind) => {
        let title = el.image.name.replace("(", "_")
        const filePath = `/blog/images/${Date.now()}_${title}`;
        const fileRef = this.storage.ref(filePath);
        const task = this.storage.upload(filePath, el.image);

        task.snapshotChanges().pipe(
          finalize(() => {
            fileRef.getDownloadURL().subscribe(res => {
              if (res) {
                contentList[el.index].image = res;
                contentList[el.index].imageFile = null;
                if (ind == images.length - 1) {
                  console.log('guarda');
                  doc.update({ content: contentList })
                }
              }
            })
          })
        )
          .subscribe();
      })

    } else {
      doc.update({ content: contentList })
    }





  }

  changeStatus(id, status) {
    const doc = this.af.firestore.collection(`blog`).doc(id);
    return doc.update({ status: status })
  }

  deletePost(id) {
    const doc = this.af.firestore.collection(`blog`).doc(id);
    return doc.delete()
  }

  //email

  sendEmail(message): Observable<any> {
    let doc = this.af.firestore.collection(`mail`).doc();
    doc.set(message)
    return this.af.collection(`mail`).doc(doc.id).valueChanges()
      .pipe(
        distinctUntilChanged(),
        map(mail => {
          if (mail['delivery']) {
            if (mail['delivery']['state'] == 'SUCCESS') {
              return mail
            }
          }
        })
      )
  }
}
